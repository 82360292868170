import Vue from 'vue'
import Vuex from 'vuex'
import Setting from './Setting/index'
import Ecommerce from './Ecommerce/index'
import Auth from './modules/auth'
import hotels from './modules/hotels'
import slider from './modules/slider'
import governorate from './modules/governorate'
import cities from './modules/cities'
import services from './modules/services'
import features from './modules/features'
import landmarks from './modules/landmark'
import Questions from './modules/questions'
import Offers from './modules/offers'
import Contacts from './modules/contacts'
import Permissions from './modules/permissions'
import Roles from './modules/roles'
import Users from './modules/users'
import Rooms from './modules/rooms'
import Clients from './modules/clients'
import Currency from './modules/currency'
import Reservations from './modules/reservations'
import Notifications from './modules/notifications'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    Setting,
    Ecommerce,
    Auth,
    slider,
    hotels,
    governorate,
    cities,
    services,
    features,
    landmarks,
    Questions,
    Contacts,
    Permissions,
    Roles,
    Users,
    Rooms,
    Offers,
    Clients,
    Currency,
    Reservations,
    Notifications
  },
  state: {
    loaderState: false
  },
  mutations: {
    changeLoaderStatus (state, data) {
      state.loaderState = data
    }
  },
  actions: {
  },
  getters: {
    loaderState: (state) => state.loaderState
  },
  strict: debug
})
