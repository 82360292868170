import '@babel/polyfill'
import Vue from 'vue'
import 'mutationobserver-shim'
import './Utils/fliter'
import './config/firebase'
import App from './App.vue'
import router from './router'
import store from './store'
import Raphael from 'raphael/raphael'
import './plugins'
// import './registerServiceWorker'
import AlgoliaComponents from 'vue-instantsearch'
import i18n from './i18n'
import './directives'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/dist/sweetalert2.min.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import OneSignalVue from 'onesignal-vue'

Vue.use(OneSignalVue)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDcH70GTe-9uRhq-i8B7xWoybbPf8o-4OE',
    libraries: 'places'
  }
})

global.Raphael = Raphael

const mixin = {
  data () {
    return {
      permissions_test: ['get-client', 'show-client', 'store-client', 'add-to-wallet-client', 'update-client', 'delete-client', 'get-currency',
        'show-currency',
        'update-currency',
        'get-hotel',
        'store-hotel',
        'show-hotel',
        'update-hotel',
        'active-hotel',
        'showInWebsite-hotel',
        'delete-hotel',
        'get-governorate',
        'show-governorate',
        'store-governorate',
        'update-governorate',
        'delete-governorate',
        'get-city',
        'show-city',
        'store-city',
        'update-city',
        'delete-city',
        'get-service',
        'store-service',
        'show-service',
        'update-service',
        'delete-service',
        'get-feature',
        'store-feature',
        'show-feature',
        'update-feature',
        'delete-feature',
        'get-landmarks',
        'store-landmarks',
        'show-landmarks',
        'update-landmarks',
        'delete-landmarks',
        'get-offer',
        'store-offer',
        'show-offer',
        'update-offer',
        'delete-offer',
        'get-commonQuestion',
        'show-commonQuestion',
        'store-commonQuestion',
        'update-commonQuestion',
        'delete-commonQuestion',
        'show-contactUs', 'get-contactUs', 'store-contactUs', 'answer-message-contactUs', 'update-contactUs', 'delete-contactUs',
        'get-role',
        'show-role',
        'store-role',
        'update-role',
        'delete-role',
        'get-permission',
        'show-permission',
        'store-permission',
        'update-permission',
        'delete-permission',
        'delete-user',
        'update-user',
        'store-user',
        'show-user',
        'get-user',
        'assign-to-user-permission',
        'assign-to-user-role',
        'get-mainSlider',
        'store-mainSlider',
        'show-mainSlider',
        'update-mainSlider',
        'delete-mainSlider',
        'get-sliderBody',
        'store-sliderBody',
        'show-sliderBody',
        'update-sliderBody',
        'delete-sliderBody'
      ]
    }
  },
  computed: {
    ...mapGetters(['loaderState', 'sliders', 'slider', 'offers', 'offer', 'governorates', 'cities', 'services', 'features', 'landmarks', 'question', 'contacts', 'permissions', 'roles', 'role', 'users', 'hotels', 'hotel', 'userData', 'rooms', 'room', 'clients', 'transactionHistory', 'currency']),
    userInfo () {
      return JSON.parse(localStorage.getItem('booking-admin-info')).userAuth || false
    }
  },
  methods: {
    canAccess (permissions) {
      return permissions.some(item => this.permissions_test.includes(item))
    },
    ...mapMutations(['SET_HOTEL']),
    ...mapActions([
      'loginUser',
      'loginOut',

      'getHotels',
      'getHotel',
      'addHotels',
      'updateHotels',
      'removeHotels',
      'activeHotel',
      'showingHotel',

      'getGovernorates',
      'addGovernorates',
      'updateGovernorates',
      'removeGovernorates',

      'getSliders',
      'showSlider',
      'addSliders',
      'addSliderBody',
      'updateSliders',
      'removeSliders',

      'getCities',
      'getTransactionHistory',
      'addCities',
      'updateCities',
      'removeCities',

      'getServices',
      'addServices',
      'updateServices',
      'removeServices',

      'getFeatures',
      'addFeatures',
      'updateFeatures',
      'removeFeatures',

      'getLandmarks',
      'addLandmarks',
      'updateLandmarks',
      'removeLandmarks',

      'getQuestion',
      'addQuestion',
      'updateQuestion',
      'removeQuestion',

      'getContacts',
      'sendAnswer',
      'removeContacts',

      'getFloors',

      'getPermissions',
      'addPermissions',
      'updatePermissions',
      'removePermissions',
      'assignPermissionsToUsers',

      'getRoles',
      'showRole',
      'addRoles',
      'updateRoles',
      'removeRoles',

      'getUsers',
      'getHotelUsers',
      'addUsers',
      'updateUsers',
      'removeUsers',

      'getRooms',
      'getRoom',
      'addRooms',
      'updateRooms',
      'removeRooms',

      'getOffers',
      'getOffer',
      'addOffers',
      'updateOffers',
      'removeOffers',

      'getClients',
      'addClients',
      'updateClients',
      'removeClients',
      'addWallet',

      'getCurrency',
      'addCurrency',
      'updateCurrency',
      'removeCurrency'
    ]),
    confermMsg (action) {
      Swal.fire({
        title: i18n.t('delete-title'),
        text: i18n.t('delete-hint'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: i18n.t('close'),
        confirmButtonText: i18n.t('confirm')
      }).then((result) => {
        if (result.isConfirmed) {
          action()
        }
      })
    },
    resetObject (target) {
      for (var key in target) {
        if (typeof target[key] === 'number') {
          target[key] = 0
        } else if (typeof target[key] === 'string') {
          target[key] = ''
        } else if (typeof target[key] === 'boolean') {
          target[key] = false
        } else if (Array.isArray(target[key])) {
          target[key] = []
        } else {
          target[key] = null
        }
      }

      return target
    },
    returnData (obg) {
      var data = new FormData()

      for (var key in obg) {
        if (obg[key] !== '' && obg[key] !== null) {
          data.append(key, obg[key])
        }
      }

      return data
    }
  }
}
Vue.mixin(mixin)
Vue.use(AlgoliaComponents)

Vue.config.productionTip = false

const vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  beforeCreate () {
    // Example prompting for notification permission
    this.$OneSignal.User.PushSubscription.optIn()
  },
  beforeMount () {
    this.$OneSignal.init({ appId: 'd8232fdf-f28d-4bc8-90df-167430fb8b2a' }).then(() => {
      this.$OneSignal.login(this.userInfo.id)
    })
  }
}).$mount('#app')

window.vm = vm
